export const colorPalette: { [key: string]: string } = {

    white: '#ffffff',
    white10: '#FFFFFFDE',
    primary: '#EEB316',
    black: '#000000',
    black950: '#05060F',
    black900: '#231F20',
    blue600: '#4856fd',
    black850: '#2B2B2B',
    gray10: '#F9FAFB',
    gray20: '#eceff2',
    red: '#d32f2f',
    green: '#22a221',
    green100: '#059c91',
    gray200: '#EEEEEE',
    gray600: '#757575',
    gray50: '#FAFAFA',
    green400: '#00BFA5',
    green50: '#E0F2F1',
    orange400: '#DD2C00',
    orange50: '#FBE9E7',
    red400: '#D50000',
    red50: '#FFEBEE',
    blue400: '#0091EA',
    blue50: '#E3F2FD',
    purple400: '#8b00bf',
    purple50: '#dfc6f2',
    yellow400: '#bfbe00',
    yellow50: '#ffffcf',
};