
import IconifyWidget from 'uiKits/iconify/IconifyWidget';

const getIcon = (name: string) => <IconifyWidget icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'داشبورد',
    name: 'dashboard',
    path: '/panel/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'کاربران',
    name: 'user',
    path: '/panel/user',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'استخدام',
    name: 'candidate',
    path: '/panel/candidate',
    icon: getIcon('eva:person-add-fill'),
  },

  {
    title: 'دسته بندی',
    name: 'category',
    path: '/panel/category',
    icon: getIcon('eva:layers-fill'),
  },
  {
    title: 'کامنت ها',
    name: 'comment',
    path: '/panel/comment',
    icon: getIcon('ant-design:message-filled'),
  },
  {
    title: 'بلاگ',
    name: 'blog',
    path: '/panel/blog',
    icon: getIcon('bxl:blogger'),
  },
  {
    title: 'htmlpart',
    name: 'htmlpart',
    path: '/panel/htmlpart',
    icon: getIcon('bxl:blogger'),
  },

  {
    title: 'کد تخفیف',
    name: 'discount',
    path: '/panel/dicount',
    icon: getIcon('eva:code-fill'),
  },
  {
    title: 'سوالات متداول',
    name: 'faq',
    path: '/panel/faq',
    icon: getIcon('eva:question-mark-circle-fill'),

  },
  {
    title: 'سفارشات',
    name: 'order',
    path: '/panel/order',
    icon: getIcon('eva:inbox-fill'),
  },
  {
    title: 'پروژها',
    name: 'project',
    path: '/panel/project',
    icon: getIcon('eva:cube-fill'),
  },
  {
    title: 'شعبه ها',
    name: 'branch',
    path: '/panel/branch',
    icon: getIcon('mingcute:git-branch-fill'),
  },
  {
    title: 'اسلایدر',
    name: 'slider',
    path: '/panel/slider',
    icon: getIcon('eva:image-2-fill'),
  },
  {
    title: 'فایل ها',
    name: 'catalogfile',
    path: '/panel/catalogfile',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'ارتباط با ما',
    name: 'contactUs',
    path: '/panel/contact-us',
    icon: getIcon('mingcute:headphone-2-fill'),
  },
  {
    title: 'تنظیمات',
    name: 'setting',
    path: '/panel/setting',
    icon: getIcon('ant-design:setting-filled'),
  },
  {
    title: 'محصولات',
    name: 'product',
    path: '/panel/product',
    icon: getIcon('eva:shopping-cart-fill'),
    children: [
      {
        title: 'لیست محصولات',
        path: '/panel/product-list',
        // Icon: getIcon('eva:people-fill'),
      },
      {
        title: 'دسته بندی محصول',
        path: '/panel/product-category',
      },
      {
        title: 'قالب های محصول',
        path: '/panel/product-template',
      },
      {
        title: 'ویژگی های محصول',
        path: '/panel/product-attribute',
      },

    ],
  },

  {
    title: 'نمایندگی',
    name: 'agencie',
    path: '/panel/agencie',
    icon: getIcon('eva:clipboard-fill'),
    children: [
      {
        title: 'لیست درخواست ها',
        path: '/panel/agencie-list',
        // Icon: getIcon('eva:people-fill'),
      },
      {
        title: 'لیست سرویس دهی شهرها',
        path: '/panel/agencie-city',
        // Icon: getIcon('eva:people-fill'),
      },

    ],
  },

];

export default navConfig;
