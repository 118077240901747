import { Routes, Route } from 'react-router-dom';
import { Suspense, lazy, memo } from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import {
    AGENCIES_URL,
    BLOG_URL,
    BRANCH_URL,
    CANDIDATE_URL,
    CATALOG_FILE_URL,
    CATEGORY_URL,
    CITY_URL,
    COMMENT_URL,
    CONFIRM_CODE_URL,
    CONTACT_US_URL,
    DASHBOARD_URL,
    DISCOUNT_URL,
    FAQ_URL,
    FORGET_PASSWORD_URL,
    HTML_PART_URL,
    ORDER_URL,
    PANEL_URL,
    PRODUCT_ATTRIBUTE_URL,
    PRODUCT_CATEGORY_URL,
    PRODUCT_LIST_URL,
    PRODUCT_TEMPLATE_URL,
    PRODUCT_VARIANT_URL,
    PROJECT_URL,
    RESET_PASSWORD_URL,
    ROOT_URL,
    SETTING_URL,
    SLIDER_URL,
    USER_URL,
    _TOKEN_NAME,
} from 'config/urls';
import AuthLayout from 'layouts/AuthLayout';
import MainLayout from 'layouts/MainLayout';

const LoginPage = lazy(() => import('pages/auth/login'));
const ForgetPage = lazy(() => import('pages/auth/forgetPassword'));
const ResetPage = lazy(() => import('pages/auth/resetPassword'));
const ConfirmCodePage = lazy(() => import('pages/auth/confirmCode'));

const UserPage = lazy(() => import('pages/user'));
const CandidatesPage = lazy(() => import('pages/candidates'));
const AgenciesPage = lazy(() => import('pages/agencies'));
const CategoryPage = lazy(() => import('pages/category'));
const CityPage = lazy(() => import('pages/city'));
const DiscountPage = lazy(() => import('pages/discount'));
const FaqPage = lazy(() => import('pages/faq'));
const OrderPage = lazy(() => import('pages/order'));
const ProjectPage = lazy(() => import('pages/project'));
const SliderPage = lazy(() => import('pages/slider'));
const ProductPage = lazy(() => import('pages/product'));
const ProductCategoryPage = lazy(() => import('pages/productCategory'));
const ProductAttributePage = lazy(() => import('pages/productAttribute'));
const ProductTemplatePage = lazy(() => import('pages/productTemplate'));
const ProductVariantPage = lazy(() => import('pages/productVariant'));
const AddProductVariantPage = lazy(() => import('pages/addProductVariant'));
const OrderDetailPage = lazy(() => import('pages/orderDetail'));
const UserDetailPage = lazy(() => import('pages/userDetail'));
const UserOrdersPage = lazy(() => import('pages/userOrders'));
const CommentPage = lazy(() => import('pages/comment'));
const BlogPage = lazy(() => import('pages/blog'));
const BlogCreatePage = lazy(() => import('pages/createBlog'));
const SettingPage = lazy(() => import('pages/setting'));
const DashboardPage = lazy(() => import('pages/dashboard'));
const ContactUsPage = lazy(() => import('pages/contactUs'));
const HtmlPartPage = lazy(() => import('pages/htmlPart'));
const BranchPage = lazy(() => import('pages/branch'));
const FactorPage = lazy(() => import('pages/orderFactor'));
const CatalogFilePage = lazy(() => import('pages/catalogfile'));

const LoadingCircle = memo(() => (
    <CircularProgress
        sx={{
            m: 'auto',
            position: 'fixed',
            top: 'calc(50% - 20px)',
            left: 'calc(50% - 20px)',
            transform: 'translate(-50%,50%)',
        }}
    />
));

const MainRoutes = () => {

    return (

        <Routes>

            <Route path={ROOT_URL} element={<AuthLayout />} >

                <Route
                    index={true}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <LoginPage />
                        </Suspense>
                    }
                />

                <Route
                    path={FORGET_PASSWORD_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ForgetPage />
                        </Suspense>
                    }
                />

                <Route
                    path={RESET_PASSWORD_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ResetPage />
                        </Suspense>
                    }
                />

                <Route
                    path={CONFIRM_CODE_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ConfirmCodePage />
                        </Suspense>
                    }
                />

            </Route>

            <Route path={PANEL_URL} element={<MainLayout />} >

                <Route
                    path={CONTACT_US_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ContactUsPage />
                        </Suspense>
                    }
                />

                <Route
                    path={USER_URL}
                >
                    <Route
                        index={true}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <UserPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={':id'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <UserDetailPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={'orders/:id'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <UserOrdersPage />
                            </Suspense>
                        }
                    />
                </Route>

                <Route
                    path={CANDIDATE_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <CandidatesPage />
                        </Suspense>
                    }
                />
                <Route
                    path={AGENCIES_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <AgenciesPage />
                        </Suspense>
                    }
                />
                <Route
                    path={COMMENT_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <CommentPage />
                        </Suspense>
                    }
                />
                <Route
                    path={CITY_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <CityPage />
                        </Suspense>
                    }
                />
                <Route
                    path={FAQ_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <FaqPage />
                        </Suspense>
                    }
                />

                <Route
                    path={PROJECT_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ProjectPage />
                        </Suspense>
                    }
                />

                <Route
                    path={SLIDER_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <SliderPage />
                        </Suspense>
                    }
                />
                <Route
                    path={PRODUCT_LIST_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ProductPage />
                        </Suspense>
                    }
                />
                <Route
                    path={PRODUCT_CATEGORY_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ProductCategoryPage />
                        </Suspense>
                    }
                />
                <Route
                    path={PRODUCT_ATTRIBUTE_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ProductAttributePage />
                        </Suspense>
                    }
                />
                <Route
                    path={PRODUCT_TEMPLATE_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <ProductTemplatePage />
                        </Suspense>
                    }
                />
                <Route
                    path={PRODUCT_VARIANT_URL}
                >
                    <Route
                        index={true}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <ProductVariantPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={'create/:id'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <AddProductVariantPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={'create'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <AddProductVariantPage />
                            </Suspense>
                        }
                    />
                </Route>

                <Route
                    path={DISCOUNT_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <DiscountPage />
                        </Suspense>
                    }
                />

                <Route
                    path={DASHBOARD_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <DashboardPage />
                        </Suspense>
                    }
                />

                <Route
                    path={CATEGORY_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <CategoryPage />
                        </Suspense>
                    }
                />
                <Route
                    path={CATALOG_FILE_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <CatalogFilePage />
                        </Suspense>
                    }
                />
                <Route
                    path={HTML_PART_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <HtmlPartPage />
                        </Suspense>
                    }
                />
                <Route
                    path={BRANCH_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <BranchPage />
                        </Suspense>
                    }
                />
                <Route
                    path={BLOG_URL}
                >
                    <Route
                        index={true}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <BlogPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={'createBlog/:id'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <BlogCreatePage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={'createBlog'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <BlogCreatePage />
                            </Suspense>
                        }
                    />
                </Route>

                <Route
                    path={ORDER_URL}
                >
                    <Route
                        index={true}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <OrderPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={':id'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <OrderDetailPage />
                            </Suspense>
                        }
                    />
                    <Route
                        path={'factor/:id'}
                        element={
                            <Suspense fallback={<LoadingCircle />}>
                                <FactorPage />
                            </Suspense>
                        }
                    />
                </Route>

                <Route
                    path={SETTING_URL}
                    element={
                        <Suspense fallback={<LoadingCircle />}>
                            <SettingPage />
                        </Suspense>
                    }
                />

            </Route>

        </Routes>
    );

};

export default MainRoutes;