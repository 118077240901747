import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { ROOT_URL } from './urls';

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!

    if (isRejectedWithValue(action)) {

        if (action?.payload.status === 401) {

            window.location.replace(ROOT_URL);
            toast.error('لطفا وارد شوید');

            return;
        }

        toast.error(action.payload?.data?.Message ?? '');
    }

    return next(action);
};