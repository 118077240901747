import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    productAttributeFormModal: {
        isOpen: false,
        id: undefined
    },
    productAttributeDeleteModal: {
        isOpen: false,
        id: undefined
    },
    productAttributeInfo: {}
};

export const productAttributeSlice = createSlice({
    name: 'productAttribute',
    initialState,
    reducers: {
        productAttributeModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.productAttributeFormModal.isOpen = action.payload.isOpen;
            state.productAttributeFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        productAttributeRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.productAttributeDeleteModal.isOpen = action.payload.isOpen;
            state.productAttributeDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        productAttributeInfoAction: (state, action: PayloadAction<any>) => {
            state.productAttributeInfo = action.payload;
            return state;
        },

    },
});

export const { productAttributeModalAction, productAttributeRemoveModalAction, productAttributeInfoAction } = productAttributeSlice.actions;
