import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    candidatesFormModal: {
        isOpen: false,
        id: ''
    },
    candidatesDeleteModal: {
        isOpen: false,
        id: ''
    },
    candidatesInfo: {}
};

export const candidatesSlice = createSlice({
    name: 'candidates',
    initialState,
    reducers: {
        candidatesModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.candidatesFormModal.isOpen = action.payload.isOpen;
            state.candidatesFormModal.id = action.payload.id ?? '';
            return state;
        },
        candidatesRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.candidatesDeleteModal.isOpen = action.payload.isOpen;
            state.candidatesDeleteModal.id = action.payload.id ?? '';
            return state;
        },
        candidatesInfoAction: (state, action: PayloadAction<any>) => {
            state.candidatesInfo = action.payload;
            return state;
        },

    },
});

export const { candidatesModalAction, candidatesRemoveModalAction, candidatesInfoAction } = candidatesSlice.actions;
