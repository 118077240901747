import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    projectsFormModal: {
        isOpen: false,
        id: undefined
    },
    projectsDeleteModal: {
        isOpen: false,
        id: undefined
    },
    projectsInfo: {}
};

export const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        projectsModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.projectsFormModal.isOpen = action.payload.isOpen;
            state.projectsFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        projectsRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.projectsDeleteModal.isOpen = action.payload.isOpen;
            state.projectsDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        projectsInfoAction: (state, action: PayloadAction<any>) => {
            state.projectsInfo = action.payload;
            return state;
        },

    },
});

export const { projectsModalAction, projectsRemoveModalAction, projectsInfoAction } = projectsSlice.actions;
