
import { ApiType } from 'interfaces';
import { api } from './api';

export const userApi = api.injectEndpoints({

    endpoints: (build) => ({

        userSearchList: build.query<any, { search: string }>({
            query: ({
                search = '',
            }) => ({
                url: `/admin/user/search?Search=${search}`,
            }),

        }),
        userList: build.query<ApiType.GetQueryResponseParam<ApiType.GetQueryUser>, ApiType.GetQueryParams>({
            query: ({
                page = 1,
                limit = 8,
                search = '',
                fromDate = '',
                toDate = '',
            }) => ({
                url: `/admin/user?limit=${limit}&page=${page}&search=${search}&fromDate=${fromDate}&toDate=${toDate}`,
            }),
            providesTags: ['User'],

        }),
        getUserById: build.query<ApiType.GetUser, ApiType.GetQueryById>({
            query: ({ id }) => ({
                url: `/admin/user/${id}`,
            }),

        }),
        createUser: build.mutation<any, ApiType.CreateUser>({
            query: (body) => ({
                url: '/admin/user',
                method: 'POST',
                body
            }),
            invalidatesTags: ['User']
        }),
        updateUser: build.mutation<any, ApiType.CreateUser>({
            query: (body) => ({
                url: '/admin/user',
                method: 'PUT',
                body
            }),
            invalidatesTags: ['User']
        }),
        removeUser: build.mutation<any, ApiType.DeleteQueryById>({
            query: ({ id }) => ({
                url: `/admin/user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['User']
        }),
        getUserInfoById: build.query<ApiType.GetUser, ApiType.GetQueryById>({
            query: ({ id }) => ({
                url: `/admin/user/${id}/address`,
            }),

        }),
        userProfile: build.query<any, any>({
            query: () => ({
                url: '/account/profile',
            }),
        }),
        userOrdersList: build.query<ApiType.GetQueryResponseParam<ApiType.GetQueryUser>, ApiType.GetQueryParams>({
            query: ({
                id = '',
                page = 1,
                limit = 8,
                search = '',
            }) => ({
                url: `/admin/user/userorders?id=${id}&limit=${limit}&page=${page}&search=${search}`,
            }),
        }),

    }),
});

export const {
    useUserListQuery,
    useCreateUserMutation,
    useGetUserByIdQuery,
    useGetUserInfoByIdQuery,
    useUpdateUserMutation,
    useRemoveUserMutation,
    useUserProfileQuery,
    useUserSearchListQuery,
    useUserOrdersListQuery
} = userApi;