import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    agenciesFormModal: {
        isOpen: false,
        id: ''
    },
    agenciesDeleteModal: {
        isOpen: false,
        id: ''
    },
    agenciesInfo: {}
};

export const agenciesSlice = createSlice({
    name: 'agencies',
    initialState,
    reducers: {
        agenciesModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.agenciesFormModal.isOpen = action.payload.isOpen;
            state.agenciesFormModal.id = action.payload.id ?? '';
            return state;
        },
        agenciesRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.agenciesDeleteModal.isOpen = action.payload.isOpen;
            state.agenciesDeleteModal.id = action.payload.id ?? '';
            return state;
        },
        agenciesInfoAction: (state, action: PayloadAction<any>) => {
            state.agenciesInfo = action.payload;
            return state;
        },

    },
});

export const { agenciesModalAction, agenciesRemoveModalAction, agenciesInfoAction } = agenciesSlice.actions;
