import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    userFormModal: {
        isOpen: false,
        id: undefined
    },
    userDeleteModal: {
        isOpen: false,
        id: undefined
    },
    userFilterModal: {
        isOpen: false,
    },
    userInfo: {}
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        userModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.userFormModal.isOpen = action.payload.isOpen;
            state.userFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        userRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.userDeleteModal.isOpen = action.payload.isOpen;
            state.userDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        userFilterModalAction: (state, action: PayloadAction<{ isOpen: boolean }>) => {
            state.userFilterModal.isOpen = action.payload.isOpen;
            return state;
        },
        userInfoAction: (state, action: PayloadAction<any>) => {
            state.userInfo = action.payload;
            return state;
        },

    },
});

export const { userModalAction, userRemoveModalAction, userInfoAction, userFilterModalAction } = userSlice.actions;
