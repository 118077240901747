import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { MainStyle, RootStyleMain } from './style';
import { _TOKEN_NAME } from 'config/urls';
import { useUserProfileQuery } from 'api/User';
import { userInfoAction } from 'slices/userSlice';
import { useAppDispatch } from 'hooks/useRedux';
import DashboardSidebar from './DashboardSidebar';
import Header from './Header';

const MainLayout = () => {

    const dispatch = useAppDispatch();

    const [open, setOpen] = useState(false);

    const { data } = useUserProfileQuery('getProfile', {
        skip: !localStorage.getItem(_TOKEN_NAME),
    });

    dispatch(userInfoAction(data));

    return (
        <RootStyleMain>

            <Header onOpenSidebar={() => setOpen(true)} />

            <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

            <MainStyle>
                <Outlet />
            </MainStyle>

        </RootStyleMain>
    );
};

export default MainLayout;