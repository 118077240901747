import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    categoryFormModal: {
        isOpen: false,
        id: undefined
    },
    categoryDeleteModal: {
        isOpen: false,
        id: undefined
    },
    categoryInfo: {}
};

export const categorySlice = createSlice({
    name: 'category',
    initialState,
    reducers: {
        categoryModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.categoryFormModal.isOpen = action.payload.isOpen;
            state.categoryFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        categoryRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.categoryDeleteModal.isOpen = action.payload.isOpen;
            state.categoryDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        categoryInfoAction: (state, action: PayloadAction<any>) => {
            state.categoryInfo = action.payload;
            return state;
        },

    },
});

export const { categoryModalAction, categoryRemoveModalAction, categoryInfoAction } = categorySlice.actions;
