import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {

    proVariantDeleteModal: {
        isOpen: false,
        id: ''
    },
};

export const productVariantSlice = createSlice({
    name: 'productVariant',
    initialState,
    reducers: {
        ProVariantRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.proVariantDeleteModal.isOpen = action.payload.isOpen;
            state.proVariantDeleteModal.id = action.payload.id ?? '';
            return state;
        },

    },
});

export const { ProVariantRemoveModalAction } = productVariantSlice.actions;
