import { colorPalette } from 'uiKits/colors';

export class UtilsHelper {

    // Tslint:disable
    public static fixFarsiForSearch = (s = '') => {

        const replaces = [
            [/ي/g, 'ی'],
            [/ك/g, 'ک'],
            [/۰/g, '0'],
            [/۱/g, '1'],
            [/۲/g, '2'],
            [/۳/g, '3'],
            [/۴/g, '4'],
            [/۵/g, '5'],
            [/۶/g, '6'],
            [/۷/g, '7'],
            [/۸/g, '8'],
            [/۹/g, '9'],
        ];
        //  YEKE
        return !!s && s.toString().length > 0 ? replaces.reduce((_elm, [from, to]) => _elm.replace(from, to.toString()), s.toString()) : '';
    };

    public static threeDigitSeparator = (value: any) => {
        try {
            if (!value) {
                return '';
            }

            const isValueTypeSuitable = typeof value === 'number' || typeof value === 'string';
            if (!isValueTypeSuitable) {
                return '';
            }

            // Convert the `value` to string
            // Value += '';

            return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
            // Return value.toLocaleString('en')
        }
        catch (e) {
            return '';
        }
    };

    public static getYear = () => {
        const date = new Date();
        const fullYear = date.getFullYear();

        const years: { id: number, title: string }[] = [];

        for (let i = 0; i < 34; i++) {
            years.push({
                id: fullYear - 621 - i,
                title: fullYear - 621 - i + '-' + (fullYear - i),
            });
        }
        return years;
    };

    public static renderColor = (type: number) => {
        if (type === UtilsHelper.orderStatusEnum[0].type) {
            return colorPalette.orange400;
        }
        if (type === UtilsHelper.orderStatusEnum[1].type) {
            return colorPalette.yellow400;
        }
        if (type === UtilsHelper.orderStatusEnum[2].type) {
            return colorPalette.purple400;
        }
        if (type === UtilsHelper.orderStatusEnum[3].type) {
            return colorPalette.blue400;
        }
        if (type === UtilsHelper.orderStatusEnum[4].type) {
            return colorPalette.green400;
        }
        else { return colorPalette.gray600; }
    };

    public static renderBg = (type: number) => {
        if (type === UtilsHelper.orderStatusEnum[0].type) { return colorPalette.orange50; }
        if (type === UtilsHelper.orderStatusEnum[1].type) { return colorPalette.yellow50; }
        if (type === UtilsHelper.orderStatusEnum[2].type) { return colorPalette.purple50; }
        if (type === UtilsHelper.orderStatusEnum[3].type) { return colorPalette.blue50; }
        if (type === UtilsHelper.orderStatusEnum[4].type) { return colorPalette.green50; }
        else { return colorPalette.gray200; }
    };

    public static levelEducationEnum = [
        { name: 'None', pName: 'هیچکدام', value: 1 },
        { name: 'Elementary', pName: 'دبستان', value: 2 },
        { name: 'Middle', pName: 'راهنمایی', value: 3 },
        { name: 'High', pName: 'دبیرستان', value: 4 },
        { name: 'Bachelor', pName: 'لیسانس', value: 5 },
        { name: 'Master', pName: 'فوق لبسانس', value: 6 },
        { name: 'PhD', pName: 'دکتری', value: 7 },
        { name: 'Other', pName: 'موارد دیگر', value: 8 },

    ];

    public static userRoleEnum = [
        { name: 'ادمین', type: 1 },
        { name: 'کاربر عادی', type: 2 },
        { name: 'مالی', type: 3 },
        { name: 'پشتیبان', type: 4 },
        { name: 'سئوکار', type: 5 },

    ];

    public static RelationShipStatusEnum = [
        { name: 'مجرد', type: 1 },
        { name: 'متاهل', type: 2 },
        { name: 'جداشده با فرزند', type: 3 },

    ];

    public static genderEnum = [
        { name: 'آقا', type: 1 },
        { name: 'خانم', type: 2 },

    ];

    public static discountTypeEnum = [
        { name: 'منحصرا', type: 1 },
        { name: 'گروهی', type: 2 },

    ];

    public static projectTypeEnum = [
        { name: 'خانگی', type: 1 },
        { name: 'اداری', type: 2 },

    ];

    public static orderStatusEnum = [
        { name: 'لغو شده', type: 1 },
        { name: 'در انتظار تایید', type: 2 },
        { name: 'تایید شده', type: 3 },
        { name: 'در حال ارسال', type: 4 },
        { name: 'تحویل داده شده', type: 5 },
        { name: 'در سبد خرید', type: 6 },
        { name: 'آماده پرداخت', type: 7 },

    ];
    public static orderStatusListEnum = [
        { name: 'لغو شده', type: 1 },
        { name: 'در انتظار تایید', type: 2 },
        { name: 'تایید شده', type: 3 },
        { name: 'در حال ارسال', type: 4 },
        { name: 'تحویل داده شده', type: 5 },
        { name: 'آماده پرداخت', type: 7 },

    ];

    public static paymentTypeEnum = [
        { name: 'آنلاین', type: 1 },
        { name: 'نقدی', type: 2 },

    ];
    public static AttributeTypeEnum = [
        { name: 'متن', type: 1 },
        { name: 'رنگ', type: 2 },

    ];
    public static AccommodationStatusEnum = [
        { name: 'صاحب ملک', type: 1 },
        { name: 'اجاره ای', type: 2 },

    ];
    public static ActivityTypeEnum = [
        { name: 'شرکت ثبت شده', type: 1 },
        { name: 'صنفی', type: 2 },

    ];
    public static MilitaryStatusEnum = [
        { name: 'انجام نشده', type: 1 },
        { name: 'دانشجو', type: 2 },
        { name: 'معاف', type: 3 },
        { name: 'انجام شده', type: 4 },

    ];
    public static BankTypeEnum = [
        { name: 'زرین پال', type: 1 },
        { name: 'ملت', type: 2 },

    ];
    public static BlogTypeEnum = [
        { name: 'ویدئو', type: 1 },
        { name: 'اخبار', type: 2 },

    ];

    public static HtmlPartType = [
        { name: 'داکیومنت', type: 1 },
        { name: 'بنر(ویدئو)', type: 2 },
        { name: 'سایر', type: 3 },
        // { name: 'رودمپ', type: 4 },
    ];

}