import { FC } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { HeaderRootStyle, HeaderToolbarStyle } from './style';
import { headerType } from 'interfaces';
import IconifyWidget from 'uiKits/iconify/IconifyWidget';
import AccountPopover from './AccountPopover';

const Header: FC<headerType.IProps> = ({ onOpenSidebar }) => {
    return (
        <HeaderRootStyle>
            <HeaderToolbarStyle>

                <IconButton onClick={onOpenSidebar} sx={{ color: 'text.primary', display: { lg: 'none' } }}>
                    <IconifyWidget icon='eva:menu-2-fill' />
                </IconButton>

                <Box sx={{ flexGrow: 1 }} />

                <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>

                    <AccountPopover />

                </Stack>

            </HeaderToolbarStyle>
        </HeaderRootStyle>
    );
};

export default Header;