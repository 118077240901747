import { useRef, useState } from 'react';
import { alpha } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';

import { DASHBOARD_URL, ROOT_URL, USER_URL } from 'config/urls';
import { useAppSelector } from 'hooks/useRedux';
import MenuPopoverWidget from 'uiKits/menuPopover/MenuPopover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';

import avatar from 'assets/imgs/avatar_default.jpg';

const MENU_OPTIONS = [
    {
        label: 'لیست کاربران',
        icon: 'eva:home-fill',
        linkTo: USER_URL,
    },
    // {
    //     Label: 'Profile',
    //     Icon: 'eva:person-fill',
    //     LinkTo: '#',
    // },
    // {
    //     Label: 'Settings',
    //     Icon: 'eva:settings-2-fill',
    //     LinkTo: '#',
    // },
];

const AccountPopover = () => {

    const anchorRef = useRef(null);
    const { userInfo } = useAppSelector((state) => state.user);

    const [open, setOpen] = useState<any>(null);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
                sx={{
                    p: 0,
                    ...(open && {
                        '&:before': {
                            zIndex: 1,
                            content: "''",
                            width: '100%',
                            height: '100%',
                            borderRadius: '50%',
                            position: 'absolute',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                        },
                    }),
                }}
            >
                <Avatar src={avatar} alt='photoURL' />
            </IconButton>

            <MenuPopoverWidget
                open={open}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >

                <Box sx={{ my: 1.5, px: 2.5 }}>
                    <Typography variant='subtitle2' noWrap>
                        {`${userInfo?.name ?? ''} ${userInfo?.family ?? ''}`}
                    </Typography>
                    <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
                        {userInfo?.mobile ?? ''}
                    </Typography>
                </Box>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <Stack sx={{ p: 1 }}>
                    {MENU_OPTIONS.map((option) => (
                        <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Stack>

                <Divider sx={{ borderStyle: 'dashed' }} />

                <MenuItem onClick={handleClose} to={ROOT_URL} component={RouterLink} sx={{ m: 1 }}>
                    خروج
                </MenuItem>

            </MenuPopoverWidget>
        </>
    );
};

export default AccountPopover;