import { FC } from 'react';
import Popover from '@mui/material/Popover';

import { ArrowStyle } from './style';
import { menuPopoverWidgetType } from 'interfaces';

const MenuPopoverWidget: FC<menuPopoverWidgetType.IProps> = ({ children, sx, open, anchorEl, onClose, ...other }) => {
    return (
        <Popover
            open={open}
            onClose={onClose}
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            PaperProps={{
                sx: {
                    p: 1,
                    width: 200,
                    overflow: 'inherit',
                    ...sx,
                },
            }}
            {...other}
        >

            <ArrowStyle className='arrow' />

            {children}

        </Popover>
    );
};

export default MenuPopoverWidget;