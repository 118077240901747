import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    ordersFormModal: {
        isOpen: false,
        id: '',
        type: '',
    },
    ordersFilterFormModal: {
        isOpen: false,
    },
    ordersInfo: {}
};

export const ordersSlice = createSlice({
    name: 'orders',
    initialState,
    reducers: {
        ordersModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.ordersFormModal.isOpen = action.payload.isOpen;
            state.ordersFormModal.id = action.payload.id ?? '';
            return state;
        },
        ordersFilterModalAction: (state, action: PayloadAction<{ isOpen: boolean }>) => {
            state.ordersFilterFormModal.isOpen = action.payload.isOpen;
            return state;
        },
        ordersInfoAction: (state, action: PayloadAction<any>) => {
            state.ordersInfo = action.payload;
            return state;
        },

    },
});

export const { ordersModalAction, ordersFilterModalAction ,ordersInfoAction } = ordersSlice.actions;
