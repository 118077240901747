import { FC, useState } from 'react';
import { alpha, useTheme } from '@mui/material/styles';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';

import { useUserProfileQuery } from 'api/User';
import { ListItemIconStyle, ListItemStyle } from './style';
import { colorPalette } from 'uiKits/colors';
import IconifyWidget from 'uiKits/iconify/IconifyWidget';
import { UtilsHelper } from 'utils/UtilsHelper';

const NavItem: FC<{ item: any, active: any }> = ({ item, active }) => {

    const theme = useTheme();

    const isActiveRoot = active(item.path);

    const { title, path, icon, info, children } = item;

    const [open, setOpen] = useState(isActiveRoot);

    const handleOpen = () => {
        setOpen((prev: any) => !prev);
    };

    const activeRootStyle = {
        color: colorPalette.blue600,
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(colorPalette.blue600, theme.palette.action.selectedOpacity),
    };

    const activeSubStyle = {
        color: 'text.primary',
        fontWeight: 'fontWeightMedium',
    };

    if (children) {
        return (
            <>
                <ListItemStyle
                    onClick={handleOpen}
                    sx={{
                        ...(isActiveRoot && activeRootStyle),
                    }}
                >
                    <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                    <ListItemText disableTypography primary={title} sx={{ textAlign: 'right' }} />
                    {info && info}
                    <IconifyWidget
                        icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-back-fill'}
                        sx={{ width: 16, height: 16, ml: 1 }}
                    />
                </ListItemStyle>

                <Collapse in={open} timeout='auto' unmountOnExit>
                    <List component='div' disablePadding>

                        {children.map((item: any) => {
                            const { title, path } = item;
                            const isActiveSub = active(path);

                            return (
                                <ListItemStyle
                                    key={title}
                                    component={RouterLink}
                                    to={path}
                                    sx={{
                                        ...(isActiveSub && activeSubStyle),
                                    }}
                                >
                                    <ListItemIconStyle>
                                        <Box
                                            component='span'
                                            sx={{
                                                width: 4,
                                                height: 4,
                                                display: 'flex',
                                                borderRadius: '50%',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                bgcolor: 'text.disabled',
                                                transition: (theme) => theme.transitions.create('transform'),
                                                ...(isActiveSub && {
                                                    transform: 'scale(2)',
                                                    bgcolor: 'primary.main',
                                                }),
                                            }}
                                        />
                                    </ListItemIconStyle>
                                    <ListItemText disableTypography primary={title} sx={{ textAlign: 'right' }} />
                                </ListItemStyle>
                            );
                        })}
                    </List>
                </Collapse>
            </>
        );
    }

    return (
        <ListItemStyle
            component={RouterLink}
            to={path}
            sx={{
                ...(isActiveRoot && activeRootStyle),
            }}
        >
            <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
            <ListItemText sx={{ textAlign: 'right' }} disableTypography primary={title} />
            {info && info}
        </ListItemStyle>
    );
};

const NavbarSection: FC<{ navConfig: any, other?: any }> = ({ navConfig, ...other }) => {

    const { pathname } = useLocation();

    const match = (path: string) => (path ? !!matchPath({ path, end: false }, pathname) : false);

    const { data } = useUserProfileQuery({});

    let navItems = navConfig;

    if (!!data && !!data.roleId) {
        if (data?.roleId === UtilsHelper.userRoleEnum[3].type) {
            navItems = navConfig.filter((item) => item.name !== 'discount');
        } else if (data?.roleId === UtilsHelper.userRoleEnum[4].type) {
            navItems = navConfig.filter((item) => item.name === 'category' || item.name === 'blog' || item.name === 'setting');
        } else if (data?.roleId === UtilsHelper.userRoleEnum[2].type) {
            navItems = navConfig.filter((item) => item.name === 'dashboard' || item.name === 'order' || item.name === 'discount');
        } else if (data?.roleId === UtilsHelper.userRoleEnum[0].type) {
            navItems = navConfig;
        } else {
            navItems = [];
        }
    }

    return (
        <Box {...other}>
            <List disablePadding sx={{ p: 1 }}>
                {navItems.map((item: any) => (
                    <NavItem key={item.title} item={item} active={match} />
                ))}
            </List>
        </Box>
    );
};

export default NavbarSection;