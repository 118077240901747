import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    branchFormModal: {
        isOpen: false,
        id: undefined
    },
    branchDeleteModal: {
        isOpen: false,
        id: undefined
    },
    branchInfo: {}
};

export const branchSlice = createSlice({
    name: 'branch',
    initialState,
    reducers: {
        branchModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.branchFormModal.isOpen = action.payload.isOpen;
            state.branchFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        branchRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.branchDeleteModal.isOpen = action.payload.isOpen;
            state.branchDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        branchInfoAction: (state, action: PayloadAction<any>) => {
            state.branchInfo = action.payload;
            return state;
        },

    },
});

export const { branchModalAction, branchRemoveModalAction, branchInfoAction } = branchSlice.actions;
