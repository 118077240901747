import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    contactUsDetailModal: {
        isOpen: false,
        id: undefined,
        data: ''
    },
    contactUsDeleteModal: {
        isOpen: false,
        id: undefined
    },
};

export const contactUsSlice = createSlice({
    name: 'contactUs',
    initialState,
    reducers: {
        contactUsDetailModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string, data?: any }>) => {
            state.contactUsDetailModal.isOpen = action.payload.isOpen;
            state.contactUsDetailModal.id = action.payload.id ?? undefined;
            state.contactUsDetailModal.data = action.payload.data ?? '';
            return state;
        },
        contactUsRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.contactUsDeleteModal.isOpen = action.payload.isOpen;
            state.contactUsDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
    },
});

export const { contactUsDetailModalAction, contactUsRemoveModalAction } = contactUsSlice.actions;
