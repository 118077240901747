import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';

import { store } from 'store/store';
import MaterialProvider from 'materialProvider';
import Router from 'routes';

const App = () => {

  return (

    <Provider store={store}>

      <MaterialProvider>

        <Router />

        <ToastContainer

          position='top-left'
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={true}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

      </MaterialProvider>

    </Provider>

  );
};

export default App;
