
export const BASE_URL = process.env.NODE_ENV === 'production' ? 'https://api.modnor.com' : 'https://api.modnor.com';

export const _TOKEN_NAME = 'token_mod_nor';
export const _UUID = '_UUID';

export const ROOT_URL = '/';

export const FORGET_PASSWORD_URL = 'forget-password';
export const RESET_PASSWORD_URL = 'reset-password';
export const CONFIRM_CODE_URL = 'confirm-code';

export const PANEL_URL = '/panel';

// Dashboard
export const DASHBOARD_URL = 'dashboard';

// Setting
export const SETTING_URL = 'setting';

// User 
export const USER_URL = 'user';

// Comment 
export const COMMENT_URL = 'comment';

// Blog 
export const BLOG_URL = 'blog';

// Candidates
export const CANDIDATE_URL = 'candidate';

// Agencies
export const AGENCIES_URL = 'agencie-list';
export const CITY_URL = 'agencie-city';

// Category
export const CATEGORY_URL = 'category';

// Branch
export const BRANCH_URL = 'branch';

// Discoung
export const DISCOUNT_URL = 'dicount';

// Faq
export const FAQ_URL = 'faq';

// Order
export const ORDER_URL = 'order';

// Project
export const PROJECT_URL = 'project';

// Slider
export const SLIDER_URL = 'slider';

// Contact Us
export const CONTACT_US_URL = 'contact-us';

// HtmlPart
export const HTML_PART_URL = 'htmlpart';

// CatalogFile
export const CATALOG_FILE_URL = 'catalogfile';

// Product
export const PRODUCT_URL = 'product';
export const PRODUCT_LIST_URL = 'product-list';
export const PRODUCT_CATEGORY_URL = 'product-category';
export const PRODUCT_ATTRIBUTE_URL = 'product-attribute';
export const PRODUCT_VARIANT_URL = 'product-variant';
export const PRODUCT_TEMPLATE_URL = 'product-template';
