import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    blogDeleteModal: {
        isOpen: false,
        id: ''
    },
    blogFilterModal: {
        isOpen: false,
    },
    blogInfo: {}
};

export const blogSlice = createSlice({
    name: 'blog',
    initialState,
    reducers: {
        blogRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.blogDeleteModal.isOpen = action.payload.isOpen;
            state.blogDeleteModal.id = action.payload.id ?? '';
            return state;
        },
        blogFilterModalAction: (state, action: PayloadAction<{ isOpen: boolean }>) => {
            state.blogFilterModal.isOpen = action.payload.isOpen;
            return state;
        },
        blogInfoAction: (state, action: PayloadAction<any>) => {
            state.blogInfo = action.payload;
            return state;
        },

    },
});

export const {  blogRemoveModalAction, blogInfoAction, blogFilterModalAction } = blogSlice.actions;
