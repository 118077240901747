import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    htmlPartFormModal: {
        isOpen: false,
        id: undefined
    },
    htmlPartDeleteModal: {
        isOpen: false,
        id: undefined
    },
    htmlPartInfo: {}
};

export const htmlPartSlice = createSlice({
    name: 'htmlPart',
    initialState,
    reducers: {
        htmlPartModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.htmlPartFormModal.isOpen = action.payload.isOpen;
            state.htmlPartFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        htmlPartRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.htmlPartDeleteModal.isOpen = action.payload.isOpen;
            state.htmlPartDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        htmlPartInfoAction: (state, action: PayloadAction<any>) => {
            state.htmlPartInfo = action.payload;
            return state;
        },

    },
});

export const { htmlPartModalAction, htmlPartRemoveModalAction, htmlPartInfoAction } = htmlPartSlice.actions;
