import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    discountsFormModal: {
        isOpen: false,
        id: undefined
    },
    discountsDeleteModal: {
        isOpen: false,
        id: undefined
    },
    discountsInfo: {}
};

export const discountsSlice = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        discountsModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.discountsFormModal.isOpen = action.payload.isOpen;
            state.discountsFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        discountsRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.discountsDeleteModal.isOpen = action.payload.isOpen;
            state.discountsDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        discountsInfoAction: (state, action: PayloadAction<any>) => {
            state.discountsInfo = action.payload;
            return state;
        },

    },
});

export const { discountsModalAction, discountsRemoveModalAction, discountsInfoAction } = discountsSlice.actions;
