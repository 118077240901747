import { FC } from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CommonType } from '../interfaces';
import { colorPalette } from 'uiKits/colors';
import CssBaseline from '@mui/material/CssBaseline';

const MaterialProvider: FC<CommonType.IProps> = ({ children }) => {

    const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

    const theme = createTheme({
        direction: 'rtl',
        typography: {
            fontFamily: 'IRANSans !important'
        },
        palette: {
            primary: {
                main: colorPalette.black,
            }
        }
    });

    return (
        <StylesProvider jss={jss}>

            <ThemeProvider theme={theme}>
                <CssBaseline />
                {children}
            </ThemeProvider>

        </StylesProvider>

    );
};

export default MaterialProvider;