import { configureStore } from '@reduxjs/toolkit';

import { api } from 'api/api';
import { userSlice } from 'slices/userSlice';
import { agenciesSlice } from 'slices/agenciesSlice';
import { candidatesSlice } from 'slices/candidatesSlice';
import { citySlice } from 'slices/citySlice';
import { categorySlice } from 'slices/categorySlice';
import { discountsSlice } from 'slices/discountsSlice';
import { ordersSlice } from 'slices/ordersSlice';
import { faqsSlice } from 'slices/faqsSlice';
import { projectsSlice } from 'slices/projectsSlice';
import { productCategorySlice } from 'slices/productCategorySlice';
import { slidersSlice } from 'slices/slidersSlice';
import { blogSlice } from 'slices/blogSlice';
import { commentSlice } from 'slices/commentSlice';
import { productAttributeSlice } from 'slices/productAttributeSlice';
import { productTemplateSlice } from 'slices/productTemplate';
import { productVariantSlice } from 'slices/productVariantSlice';
import { productSlice } from 'slices/productSlice';
import { contactUsSlice } from 'slices/contactUsSlice';
import { htmlPartSlice } from 'slices/htmlPartSlice';
import { branchSlice } from 'slices/branchSlice';
import { catalogFileSlice } from 'slices/catalogfileSlice';
import { rtkQueryErrorLogger } from 'config/ErrorMiddleware';

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [userSlice.name]: userSlice.reducer,
        [agenciesSlice.name]: agenciesSlice.reducer,
        [candidatesSlice.name]: candidatesSlice.reducer,
        [citySlice.name]: citySlice.reducer,
        [categorySlice.name]: categorySlice.reducer,
        [discountsSlice.name]: discountsSlice.reducer,
        [ordersSlice.name]: ordersSlice.reducer,
        [faqsSlice.name]: faqsSlice.reducer,
        [projectsSlice.name]: projectsSlice.reducer,
        [productCategorySlice.name]: productCategorySlice.reducer,
        [slidersSlice.name]: slidersSlice.reducer,
        [blogSlice.name]: blogSlice.reducer,
        [commentSlice.name]: commentSlice.reducer,
        [productAttributeSlice.name]: productAttributeSlice.reducer,
        [productTemplateSlice.name]: productTemplateSlice.reducer,
        [productVariantSlice.name]: productVariantSlice.reducer,
        [productSlice.name]: productSlice.reducer,
        [contactUsSlice.name]: contactUsSlice.reducer,
        [htmlPartSlice.name]: htmlPartSlice.reducer,
        [branchSlice.name]: branchSlice.reducer,
        [catalogFileSlice.name]: catalogFileSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware().concat(api.middleware).concat(rtkQueryErrorLogger);
    },
    devTools: true
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch