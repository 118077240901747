import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, _TOKEN_NAME } from 'config/urls';

export const api = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({
        baseUrl: BASE_URL,
        prepareHeaders: (headers) => {
            const token = localStorage.getItem(_TOKEN_NAME);
            if (token) {
                headers.set('authorization', `Bearer ${token}`);
            }

            return headers;
        },

    }),
    tagTypes: [
        'User',
        'City',
        'Category',
        'Discounts',
        'Order',
        'Faqs',
        'Projects',
        'ProductCategory',
        'Sliders',
        'Comment',
        'Blog',
        'ProductAttribute',
        'ProductTemplate',
        'Product',
        'ProductVariant',
        'Setting',
        'ContactUs',
        'HtmlPart',
        'Branch',
        'CatalogFile',
    ],
    endpoints: (builder) => ({}),
});