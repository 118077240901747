import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import useResponsive from 'hooks/useResposive';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Avatar from '@mui/material/Avatar';

import { sidebarType } from 'interfaces';
import { AccountStyle, RootStyleSidebar } from './style';
import { colorPalette } from 'uiKits/colors';
import { useAppSelector } from 'hooks/useRedux';
import navConfig from 'config/navConfig';
import NavbarSection from './NavSection';

import AvatarIcon from 'assets/imgs/avatar_default.jpg';

const DRAWER_WIDTH = 280;

const account = {
    displayName: 'محسن کرمانی فر',
    email: 'demo@minimals.cc',
    photoURL: AvatarIcon,
};

const DashboardSidebar: FC<sidebarType.IProps> = ({ isOpenSidebar, onCloseSidebar }) => {

    const isDesktop = useResponsive('up', 'lg');
    const { userInfo } = useAppSelector((state) => state.user);

    const renderContent = (
        <>
            {/* <ScrollbarWidget
        //     sx={{
        //         height: 1 ,
        //         '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
        //     }}
        // > */}
            <Box sx={{ px: 2.5, py: 3, display: 'inline-flex' }}>
                {/* <Logo /> */}
            </Box>

            <Box sx={{ mb: 5, mx: 2.5 }}>
                <Link underline='none' component={RouterLink} to='#'>
                    <AccountStyle>
                        <Avatar src={account.photoURL} alt='photoURL' />
                        <Box sx={{ mr: 2 }}>
                            <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
                                {`${userInfo?.name ?? ''} ${userInfo?.family ?? ''}`}
                            </Typography>
                            <Typography variant='body2' sx={{ color: 'text.secondary' }}>
                                {userInfo?.mobile ?? ''}
                            </Typography>
                        </Box>
                    </AccountStyle>
                </Link>
            </Box>

            <NavbarSection navConfig={navConfig} />

            <Box sx={{ flexGrow: 1 }} />

            {/* <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
                <Stack alignItems="center" spacing={3} sx={{ pt: 5, borderRadius: 2, position: 'relative' }}>
                    <Box
                        component="img"
                        src="/static/illustrations/illustration_avatar.png"
                        sx={{ width: 100, position: 'absolute', top: -50 }}
                    />

                    <Box sx={{ textAlign: 'center' }}>
                        <Typography gutterBottom variant="h6">
                            Get more?
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            From only $69
                        </Typography>
                    </Box>

                    <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
                        Upgrade to Pro
                    </Button>
                </Stack>
            </Box> */}

        </>
        // </ScrollbarWidget>
    );

    return (
        <RootStyleSidebar>

            {!isDesktop && (
                <Drawer
                    open={isOpenSidebar}
                    onClose={onCloseSidebar}
                    anchor='left'
                    ModalProps={{
                        keepMounted: true
                    }}
                    variant='temporary'
                    PaperProps={{
                        sx: { width: DRAWER_WIDTH, right: isOpenSidebar ? 0 : -280, left: 'unset' },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}

            {isDesktop && (
                <Drawer
                    open
                    variant='persistent'
                    anchor='right'
                    PaperProps={{
                        sx: {
                            width: DRAWER_WIDTH,
                            bgcolor: colorPalette.gray10,
                            borderLeft: '1px solid rgba(145, 158, 171, 0.24)',
                            borderLeftStyle: 'dashed',
                        },
                    }}
                >
                    {renderContent}
                </Drawer>
            )}

        </RootStyleSidebar>
    );
};

export default DashboardSidebar;