import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    catalogFileFormModal: {
        isOpen: false,
        id: undefined
    },
    catalogFileDeleteModal: {
        isOpen: false,
        id: undefined
    },
    catalogFileInfo: {}
};

export const catalogFileSlice = createSlice({
    name: 'catalogFile',
    initialState,
    reducers: {
        catalogFileModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.catalogFileFormModal.isOpen = action.payload.isOpen;
            state.catalogFileFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        catalogFileRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.catalogFileDeleteModal.isOpen = action.payload.isOpen;
            state.catalogFileDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        catalogFileInfoAction: (state, action: PayloadAction<any>) => {
            state.catalogFileInfo = action.payload;
            return state;
        },

    },
});

export const { catalogFileModalAction, catalogFileRemoveModalAction, catalogFileInfoAction } = catalogFileSlice.actions;
