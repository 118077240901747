import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    slidersFormModal: {
        isOpen: false,
        id: undefined
    },
    slidersDeleteModal: {
        isOpen: false,
        id: undefined
    },
    slidersInfo: {}
};

export const slidersSlice = createSlice({
    name: 'sliders',
    initialState,
    reducers: {
        slidersModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.slidersFormModal.isOpen = action.payload.isOpen;
            state.slidersFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        slidersRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.slidersDeleteModal.isOpen = action.payload.isOpen;
            state.slidersDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        slidersInfoAction: (state, action: PayloadAction<any>) => {
            state.slidersInfo = action.payload;
            return state;
        },

    },
});

export const { slidersModalAction, slidersRemoveModalAction, slidersInfoAction } = slidersSlice.actions;
