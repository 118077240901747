import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    commentFormModal: {
        isOpen: false,
        id: ''
    },
    commentSelectedModal: {
        isOpen: false,
        id: ''
    },
    commentDetailModal: {
        isOpen: false,
        id: ''
    },
    commentFilterModal: {
        isOpen: false,
    },
    commentInfo: {}
};

export const commentSlice = createSlice({
    name: 'comment',
    initialState,
    reducers: {
        commentModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.commentFormModal.isOpen = action.payload.isOpen;
            state.commentFormModal.id = action.payload.id ?? '';
            return state;
        },
        commentSelectedModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.commentSelectedModal.isOpen = action.payload.isOpen;
            state.commentSelectedModal.id = action.payload.id ?? '';
            return state;
        },
        commentDetailModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.commentDetailModal.isOpen = action.payload.isOpen;
            state.commentDetailModal.id = action.payload.id ?? '';
            return state;
        },
        commentFilterModalAction: (state, action: PayloadAction<{ isOpen: boolean }>) => {
            state.commentFilterModal.isOpen = action.payload.isOpen;
            return state;
        },
        commentInfoAction: (state, action: PayloadAction<any>) => {
            state.commentInfo = action.payload;
            return state;
        },

    },
});

export const { commentModalAction, commentDetailModalAction, commentInfoAction, commentFilterModalAction, commentSelectedModalAction } = commentSlice.actions;
