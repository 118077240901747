import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    faqsFormModal: {
        isOpen: false,
        id: undefined
    },
    faqsDeleteModal: {
        isOpen: false,
        id: undefined
    },
    faqsInfo: {}
};

export const faqsSlice = createSlice({
    name: 'faqs',
    initialState,
    reducers: {
        faqsModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.faqsFormModal.isOpen = action.payload.isOpen;
            state.faqsFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        faqsRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.faqsDeleteModal.isOpen = action.payload.isOpen;
            state.faqsDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        faqsInfoAction: (state, action: PayloadAction<any>) => {
            state.faqsInfo = action.payload;
            return state;
        },

    },
});

export const { faqsModalAction, faqsRemoveModalAction, faqsInfoAction } = faqsSlice.actions;
