import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    proTemplateFormModal: {
        isOpen: false,
        id: undefined
    },
    proTemplateDeleteModal: {
        isOpen: false,
        id: undefined
    },
};

export const productTemplateSlice = createSlice({
    name: 'productTemplate',
    initialState,
    reducers: {
        ProTemplateModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.proTemplateFormModal.isOpen = action.payload.isOpen;
            state.proTemplateFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        ProTemplateRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.proTemplateDeleteModal.isOpen = action.payload.isOpen;
            state.proTemplateDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },

    },
});

export const { ProTemplateModalAction, ProTemplateRemoveModalAction } = productTemplateSlice.actions;
