import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    cityFormModal: {
        isOpen: false,
        id: '',
        type: '',
    },
    cityInfo: {}
};

export const citySlice = createSlice({
    name: 'city',
    initialState,
    reducers: {
        cityModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string, type?: string }>) => {
            state.cityFormModal.isOpen = action.payload.isOpen;
            state.cityFormModal.type = action.payload.type ?? '';
            state.cityFormModal.id = action.payload.id ?? '';
            return state;
        },
        cityInfoAction: (state, action: PayloadAction<any>) => {
            state.cityInfo = action.payload;
            return state;
        },

    },
});

export const { cityModalAction, cityInfoAction } = citySlice.actions;
