import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import useResponsive from 'hooks/useResposive';
import { Outlet } from 'react-router-dom';

import { _TOKEN_NAME, _UUID } from 'config/urls';
import { RootStyle, SectionStyle } from './style';

import LoginPng from 'assets/imgs/login.png';

const AuthLayout = () => {

    const mdUp = useResponsive('up', 'md');

    useEffect(() => {

        localStorage.removeItem(_TOKEN_NAME);
        localStorage.removeItem(_UUID);

    }, []);

    return (
        <RootStyle>

            {mdUp && (
                <SectionStyle>
                    <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
                        به پنل مدیریتی مدنور خوش آمدید
                    </Typography>
                    <img src={LoginPng} alt='login' />
                </SectionStyle>
            )}

            <Outlet />
        </RootStyle>
    );
};

export default AuthLayout;