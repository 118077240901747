import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    productCategoryFormModal: {
        isOpen: false,
        id: undefined
    },
    productCategoryDeleteModal: {
        isOpen: false,
        id: undefined
    },
    productCategoryInfo: {}
};

export const productCategorySlice = createSlice({
    name: 'productCategory',
    initialState,
    reducers: {
        productCategoryModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.productCategoryFormModal.isOpen = action.payload.isOpen;
            state.productCategoryFormModal.id = action.payload.id ?? undefined;
            return state;
        },
        productCategoryRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.productCategoryDeleteModal.isOpen = action.payload.isOpen;
            state.productCategoryDeleteModal.id = action.payload.id ?? undefined;
            return state;
        },
        productCategoryInfoAction: (state, action: PayloadAction<any>) => {
            state.productCategoryInfo = action.payload;
            return state;
        },

    },
});

export const { productCategoryModalAction, productCategoryRemoveModalAction, productCategoryInfoAction } = productCategorySlice.actions;
