import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: any = {
    productFormModal: {
        isOpen: false,
        id: ''
    },
    productDeleteModal: {
        isOpen: false,
        id: ''
    },
    productInfo: {}
};

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        productModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.productFormModal.isOpen = action.payload.isOpen;
            state.productFormModal.id = action.payload.id ?? '';
            return state;
        },
        productRemoveModalAction: (state, action: PayloadAction<{ isOpen: boolean, id?: string }>) => {
            state.productDeleteModal.isOpen = action.payload.isOpen;
            state.productDeleteModal.id = action.payload.id ?? '';
            return state;
        },
        productInfoAction: (state, action: PayloadAction<any>) => {
            state.productInfo = action.payload;
            return state;
        },

    },
});

export const { productModalAction, productRemoveModalAction, productInfoAction } = productSlice.actions;
